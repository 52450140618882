import React, { useEffect } from 'react';
import queryWhitelabelApi from '@queries/api/bridge/queryWhitelabel';
import { useRouter } from 'next/router';
import ConnectLayout from '@layout/ConnectLayout';
import HomePage from '@components/office/connect/bridge/HomePage';
import useSWR from 'swr';
import { apiSwrRequest } from '@services/http/client';
import Loader from '@components/bridge/bridge/Loader';
import useSharedState from '@hooks/useSharedState';
import User from '@utils/User';
import i18n from 'i18n';
function Index() {
  const router = useRouter();
  const {
    data: me
  } = User.useLoadMe();
  const {
    data: whitelabel
  } = useSWR(() => queryWhitelabelApi(), data => apiSwrRequest(data, 'bridgeWhitelabel'));
  const [meta, setMeta] = useSharedState('meta', {
    title: ''
  });
  useEffect(() => {
    setMeta({
      ...meta,
      title: tr('back.controller.login.title')
    });
  }, [meta]);
  useEffect(() => {
    const browserLang = navigator.language.split('-')[0];
    const defaultLang = localStorage.getItem('defaultLang');
    i18n.defaultLang = defaultLang || (i18n.available.includes(browserLang) ? browserLang : 'fr');
    router.push(router.asPath);
  }, []);
  if (me === undefined) return <div className='innerLoader'>
                <Loader />
            </div>;

  // Redirect if user already connected
  if (me?.id || whitelabel === undefined) {
    router.push('/front').catch(console.error);
    return null;
  }
  return <ConnectLayout whitelabel={whitelabel}>
            <HomePage whitelabel={whitelabel} router={router} />
        </ConnectLayout>;
}
export default Index;