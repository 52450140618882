import __Base from '@components/Base';
import _ from 'lodash';
import React from 'react';
import { serialize } from 'object-to-formdata';
import isEmail from 'validator/lib/isEmail';
import WaitingButton from '@components/bridge/bridge/WaitingButton';
import DanimInput from '@components/bridge/bridge/DanimInput';
import { axios } from '@services/http/client';
import Link from 'next/link';
import { FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { Visibility, VisibilityOff } from '@mui/icons-material';
class HomePage extends __Base {
  constructor(props) {
    super(props);
    this.state = {
      animation: {
        segments: null
      },
      form: {
        email: {
          value: '',
          error: null
        },
        password: {
          value: '',
          showPassword: false,
          error: null
        },
        tfa: {
          show: false,
          qrCode: null,
          code: {
            value: '',
            error: null
          }
        },
        submit: {
          waiting: false
        }
      }
    };
  }
  handleClickShowPassword = () => {
    this.updateState(draft => {
      draft.form.password.showPassword = !draft.form.password.showPassword;
    });
  };
  handleMouseDownPassword = event => {
    event.preventDefault();
  };
  handleInputChange = ev => {
    let target = ev.target;
    let value = _.get(target, 'type') === 'checkbox' ? target.checked : target.value;
    let name = target.name;
    this.updateState(draft => {
      draft.form[name].value = value;
    });
  };
  handleMouseEnter = ev => {
    document.getElementsByTagName('html')[0].classList.add('/entered');
  };
  handleMouseLeave = ev => {
    document.getElementsByTagName('html')[0].classList.remove('/entered');
  };
  handleSubmit = ev => {
    if (ev) ev.preventDefault();
    if (this.validateForm()) {
      if (!this.state.form.submit.waiting) {
        // avoid duplication
        this.updateState(draft => {
          draft.form.submit.waiting = true;
        }, async () => {
          const baseOptions = {
            method: 'POST',
            url: gen('tokens/generate'),
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            data: serialize({
              email: this.state.form.email.value,
              password: toBase64(this.state.form.password.value),
              // tfa_code: this.state.form.tfa.code.value || null,
              ttl: 20 * 60,
              refreshTtl: 60 * 60 * 24 * 7,
              setSessionCookie: true,
              decodePassword: true
              // oneTime: 0,
              // subject: 'me',
              // exclusive: 0
            }),
            withCredentials: true,
            underWhitelabel: !!this.props.whitelabel
          };
          await axios(baseOptions, {
            then: async data => {
              if (data?.data?.is_subject_admin) {
                this.props.router.push('/back').catch(console.error);
              } else this.props.router.push('/front').catch(console.error);
            },
            catch: error => {
              // Sentry.captureException(error);
              const errorTable = ['InvalidPassword', 'EmailNotFound', 'UserIsLocked', 'UserHasExpired', 'UserHasNotBeenActivatedYet', 'UserWhitelabelDoesNotMatch'];
              if (_.includes(errorTable, error?.meta?.type)) {
                addFlash({
                  type: 'warning',
                  message: _.includes(errorTable, error.name) ? tr(`front.flash.login.error.${_.snakeCase(error.name)}`) : tr('front.flash.login.error.default')
                });
                return this.updateState(draft => {
                  draft.form.submit.waiting = false;
                });
              }
            }
          });
        });
      }
    }
  };
  handleTfaCodeChange = ev => {
    const value = ev.target.value;
    this.updateState(draft => {
      draft.form.tfa.code.value = value;
    });
  };

  /**
   * Returns bool: true if no validation error was detected
   */
  validateForm = () => {
    let noProblem = true;
    let filled = {};
    let valid = {};

    // email
    //      filled?
    filled.email = !!this.state.form.email.value;
    this.updateState(draft => {
      draft.form.email.error = filled.email ? null : 'front.home_page.form.email.error.empty';
    });
    noProblem *= filled.email;
    //      valid?
    if (filled.email) {
      valid.email = isEmail(this.state.form.email.value.trim());
      this.updateState(draft => {
        draft.form.email.error = valid.email ? null : 'front.home_page.form.email.error.invalid';
      });
      noProblem *= valid.email;
    }

    // password
    //      filled?
    filled.password = !!this.state.form.password.value;
    this.updateState(draft => {
      draft.form.password.error = filled.password ? null : 'front.home_page.form.password.error.empty';
    });
    noProblem *= filled.password;

    // tfa code
    //      filled?
    if (this.state.form.tfa.show) {
      filled.tfa = !!this.state.form.tfa.code.value;
      this.updateState(draft => {
        draft.form.tfa.code.error = filled.tfa ? null : 'front.home_page.form.tfa.error.empty';
      });
      noProblem *= filled.tfa;
    }
    return noProblem;
  };
  render = () => {
    return <main className='connect:homePage'>
                <form name='login' className='-form' onSubmit={this.handleSubmit} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    <h1 className='-title' data-test-id='form-title'>
                        {tr('front.home_page.form.title', {}, 'connect-bridge')}
                    </h1>
                    <FormControl sx={{
          m: 1,
          width: '100%'
        }} variant='outlined'>
                        <InputLabel htmlFor='outlined-email'>
                            {tr('front.home_page.form.email.placeholder', {}, 'connect-bridge')}
                        </InputLabel>
                        <OutlinedInput id='outlined-email' type='text' label={tr('front.home_page.form.email.placeholder', {}, 'connect-bridge')} value={this.state.form.email.value} error={!!this.state.form.email.error} onChange={this.handleInputChange} autoComplete='email' name='email' />
                        {!!this.state.form.email.error && <FormHelperText error>{tr(this.state.form.email.error)}</FormHelperText>}
                    </FormControl>
                    {!this.state.form.tfa.show && <FormControl sx={{
          m: 1,
          width: '100%'
        }} variant='outlined'>
                            <InputLabel htmlFor='outlined-password'>
                                {tr('front.home_page.form.password.placeholder', {}, 'connect-bridge')}
                            </InputLabel>
                            <OutlinedInput id='outlined-password' type={this.state.form.password.showPassword ? 'text' : 'password'} name='password' endAdornment={<InputAdornment position='end'>
                                        <IconButton aria-label='toggle password visibility' onClick={this.handleClickShowPassword} onMouseDown={this.handleMouseDownPassword} edge='end'>
                                            {this.state.form.password.showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>} label={tr('front.home_page.form.password.placeholder', {}, 'connect-bridge')} value={this.state.form.password.value} error={!!this.state.form.password.error} onChange={this.handleInputChange} autoComplete='current-password' />
                            {!!this.state.form.password.error && <FormHelperText error>{tr(this.state.form.password.error)}</FormHelperText>}
                        </FormControl>}
                    {!this.state.form.tfa.show && <div className='-forgotPwd' data-test-id='forgot-pwd'>
                            <Link href='/connect/password/request'>
                                <a>{tr('front.home_page.form.forgotten_password')}</a>
                            </Link>
                        </div>}
                    {this.state.form.tfa.show && <div className='-tfa'>
                            {!!this.state.form.tfa.qrCode && <div className='-tuto'>
                                    <img alt='qr' src={this.state.form.tfa.qrCode} />
                                    <div className='-steps'>
                                        <span dangerouslySetInnerHTML={{
                __html: tr('front.home_page.form.tfa.first_time.steps.step_1')
              }} />
                                        <span dangerouslySetInnerHTML={{
                __html: tr('front.home_page.form.tfa.first_time.steps.step_2')
              }} />
                                        <span dangerouslySetInnerHTML={{
                __html: tr('front.home_page.form.tfa.first_time.steps.step_3')
              }} />
                                    </div>
                                </div>}
                            <DanimInput customAttributes={{
            root: {
              className: '-code'
            }
          }} name='tfaCode' placeholder={tr('front.home_page.form.tfa.placeholder', {}, 'connect-bridge')} value={this.state.form.tfa.code.value} error={this.state.form.tfa.error ? tr(this.state.form.tfa.error) : []} type='text' onChange={this.handleTfaCodeChange} />
                        </div>}
                    <WaitingButton customAttributes={{
          root: {
            className: '-submit'
          }
        }} type='submit' waiting={this.state.form.submit.waiting} text={tr(`front.home_page.form.submit.text.${this.state.form.tfa.show ? 'after_tfa' : 'default'}`)} />
                </form>
                {/* { !whitelabel && */}
                {/* <Link className="-registerSuggestion" href={gen('connect::user::register')} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}> */}
                {/*	{tr('front.home_page.suggestion', {}, 'connect-bridge')} */}
                {/* </Link> */}
                {/* } */}
            </main>;
  };
}
export default HomePage;